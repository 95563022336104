import styled from "styled-components";

const SingleServicePage = styled.div`
  section.sectionS2-2 {

    padding: 30px 0;

    li {
      margin-bottom: 15px;
      color: #000000;
      font-family: "Mulish";
      font-weight: 500;
    }

    h4 {
      margin-bottom: 25px;
    }

    p {
      color: #000000;
      font-family: "Mulish";
      font-weight: 500;
    }
  }

  div#serviceSectionThree {
    @media only screen and (max-device-width: 768px) {
      .col-md-5 img.img-wig {
        display: none;
      }
    }
  }

  div#serviceSectionOne {
    padding: 60px 0;

    h2 {
      max-width: 330px;
      margin-bottom: 40px;
    }

    .swiper {
      padding-bottom: 50px;
    }

    @media only screen and (max-device-width: 768px) {
      display:none;
    }

  }

  .section-s2 {
    padding: 60px 0;
  }
  .section-s4 {
    padding: 60px 0;
    p {
      color: #000000;
      font-size: 16px;
      font-family: "Mulish";
      font-weight: 500;
    }

    @media only screen and (min-device-width: 768px) {
      .col-md-3 {
        width: 29%;
      }
      .col-md-9 {
        width: 71%;
      }
    }
  }

  .section-s3 {
    padding: 60px 0;
    background: #f5f5f5;

    li {
      border-bottom: 1px solid #dedede;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-size: 15px;
      font-family: "Mulish";
      font-weight: 500;
      color: #000000;
    }

    h2 {
      font-size: 28px;
    }

    h5 {
      background: #242424;
      color: #ffffff;
      font-weight: 500 !important;
      padding: 26px;
      border-radius: 35px;
      border-bottom-right-radius: 0;
      line-height: 1.6;
    }
    p {
      color: #000000;
      font-size: 16px;
      font-family: "Mulish";
      font-weight: 500;
    }
    p.subP {
      font-size: 14px !important;
      margin-bottom: 5px !important;
    }
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .service-s1 {
    padding: 60px 0;

    h2 {
      margin-bottom: 40px;
    }

    .swiper {
      padding-bottom: 50px;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 16px;
      border-radius: 5px;
      background: #c88e23;
      opacity: 1;
    }

    span.swiper-pagination-bullet {
      background: #9c6807;
      opacity: 0.5;
    }
  }

  .imgCard h4 {
    color: #ffffff;
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 0 10px;
  }
  .imgCard .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  img.mainImg {
    border-radius: 50px;
    border-bottom-left-radius: 0;
  }

  .contentCard {
    padding: 28px;
    border-radius: 25px;
    border: 1px solid #dedede;
    margin-left: -110px;
    background: #ffffff;

    p {
      color: #000000;
      font-size: 14px;
      font-family: "Mulish";
      font-weight: 500;
    }

    h2 {
      font-size: 24px;
    }

    @media only screen and (max-device-width: 768px) {
      margin-left: 0px;
      background: #ffffff;
      margin-top: 25px;
    }
  }

  .img-wig{
    border-radius: 30px;
  }
`;

export default SingleServicePage;

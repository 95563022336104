import React from "react";
import SectionWrapper from "./sectionfour.style";
import FAQToggle from "common/components/FAQs";

const ServiceSectionFour = (props) => {
  return (
    <SectionWrapper id="serviceSectionFour" className="section-s4">
      <div className="myContainer">
        <div className="row">
          <div className="col-md-9">
            <div>
              {props.data.map((section, index) => (
                <div key={index}>
                  <h3>{section.title}</h3>
                  <p>{section.description}</p>
                  <div class="faq-description">
                    <FAQToggle faqs={section.faq} />
                  </div>
                  <p>{section.description2}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3">
            <div className="contactinfo4">
              <div className="contactinfoo">
                <img src="/help-image-1.png" alt="contact us" />
                <h4>
                  Need Help? <br />
                  Talk with Expert
                </h4>
                <p>Call Anytime</p>
                <a href="tel:+919958585649">+91-99585-85649</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ServiceSectionFour;

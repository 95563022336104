import React from "react";
import Breadcrumbs from "common/components/Breadcrumbs";
import TitleBarWrap from "./titleBar.style";

export const Titlebar = (props) => {
  return (
    <TitleBarWrap>
      <div id="titleBar2" className="row">
      {/* <section className="title-bar" style={{ background: window.innerWidth <= 768 ? `url("${props.Mobbanner}")` : `url("${props.banner || '/images/titlebar-background.jpg'}")` }} 
        > */}
        <section className="title-bar" style={{ background: `url("${props.banner || '/images/titlebar-background.jpg'}")` }} >
          <div className="title-bar-overlay"></div>
          <div className="myContainer">
            <h1 className="title-bar-heading">{props.title}</h1>
            <h6 className="title-bar-breadcrumb">
              <a href="/">Home</a> / <a href="#">{props.title}</a>
            </h6>
          </div>
        </section>
        <section className="title-bar-separator"><img className="separator-image" alt="" src="/images/separator.png" /></section>
      </div>
    </TitleBarWrap>
  );
};

export default Titlebar;

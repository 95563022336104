import styled from "styled-components";

const TitleBarWrap = styled.div`
section.title-bar-separator {
    margin-top: -60px;
}

@media only screen and (max-device-width: 768px) {
    section.title-bar-separator {
        margin-top: -20px;
    }
}

div#titleBar2 section.title-bar::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

}

div#titleBar2 section.title-bar {
    background-size: cover !important;
    background-repeat: no-repeat;
    padding-top: 130px;
    padding-bottom: 130px;
    height:460px;
    text-align: center;
    z-index: 0;

    h1.title-bar-heading {
        color: #ffffff;
        font-size: 36px;
    }

    h6.title-bar-breadcrumb {
        color: #ffffff;
        margin-top: 20px;

        a {
            color: #ffffff;
            font-weight: 400 !important;
        }
    }
}


    position: relative;
    background: #F7F2EF;

    h1.section-title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
    }

    ul {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    li {
        margin: 0 10px;
    }

    .container {
        padding: 40px 10px;
    }

    div#titleBar {
        display: block;
        position: relative;
        overflow: hidden;
    }

    #titleBar .background-image {
        background-image: url(/breadcrumb-bg.png);
        background-position: 50% 0%;
        background-size: auto;
        background-repeat: repeat-x;
        content: '';
        height: 200px;
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 0;
    }

    @media only screen and (max-width: 1440px) {
    
    }
    
    @media only screen and (max-width: 800px) {
        ul {
            flex-direction: column;
        }
        h1.section-title {
            text-align: center;
            font-size: 24px;
            font-weight: 600;
        }
    }

`;

export default TitleBarWrap;